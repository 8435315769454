import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <div className='gradbtn mt-3'>
      <Button onClick={onPresentConnectModal} {...props} className="theme-btn 3 m-0" style={{width:"100%"}}>
        {t('Connect Wallet')}
      </Button>
    </div>
  )
}

export default ConnectWalletButton
